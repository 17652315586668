@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Roboto Slab", serif;
}
footer {
  margin-top: auto;
}
main {
  flex: 1 1;
  justify-content: center;
  display: flex;
}
main div {
  display: flex;
  place-items: center;
  flex-direction: column;
  justify-content: center;
}
main p {
  font-size: 50px;
  text-align: center;
  line-height: 3rem;
}
main span {
  margin-top: 0.5rem;
  letter-spacing: 0.3rem;
}
main span a {
  letter-spacing: 0;
}
.footer {
  background: #fff !important;
}
.navbar-brand img {
  height: 40px;
  padding-left: 0.5rem;
}
.navbar-brand,
.navbar-tabs {
  place-items: center !important;
}
main span a,
.footer a {
  font-weight: bold;
  background: linear-gradient(to right, #30cfd0 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  color: #30cfd0 !important;
}
@media only screen and (max-width: 600px) {
  main span {
    margin-top: 0.5rem;
    letter-spacing: 0.1rem;
  }
}

